import React, {useState} from 'react';
import {Form, Row, Col, Button} from 'react-bootstrap';
import {useFormContext,useForm} from 'react-hook-form';
import YearMonth from './yearMonth';


const Convictions = () => {
	const {
		register,
		unregister,
		setValue,
		formState: {errors}
	} = useFormContext() || useForm();

	const [indexes, setIndexes] = useState([0]);
	const [counter, setCounter] = useState(1);

	const addConviction = () => {
		setIndexes((prevIndexes) => [...prevIndexes, counter]);
		setCounter((prevCounter) => prevCounter + 1);
	};

	const removeConviction = (index) => {
		setIndexes((prevIndexes) => [
			...prevIndexes.filter((item) => item !== index)
		]);
		unregister(`convictions[${index}].desc`);
		unregister(`convictions[${index}].year`);
		unregister(`convictions[${index}].month`);
		unregister(`convictions[${index}].moreDesc`);
	};

	return (
		<>
			<Row>
				<Col>Description</Col>
				<Col lg={2}>Year</Col>
				<Col lg={1}>Month</Col>
				<Col>More details</Col>
				<Col lg={1}></Col>
			</Row>

			{indexes.map((index) => {
				const fieldName = `convictions[${index}]`;
				register(`${fieldName}.desc`);

				let descInvalid = false;

				if (
					errors.convictions &&
					errors.convictions[`${index}`] !== undefined
				) {
					descInvalid = errors.convictions[`${index}`].desc !== undefined;
				}

				return (
					<Row className='mb-3' key={fieldName}>
						<Form.Group as={Col} controlId={`${fieldName}.desc`}>
							<Form.Control
								type='Text'
								name={`${fieldName}.desc`}
                onChange={(e)=>setValue(`${fieldName}.desc`, e.target.value)}
                isInvalid={descInvalid}
							/>
							{descInvalid && (
								<Form.Control.Feedback type='invalid'>
										{errors.convictions[`${index}`].desc.message}
								</Form.Control.Feedback>
							)}
						</Form.Group>
						<YearMonth fieldName={fieldName} register={register} years={5} /> 
						<Form.Group as={Col} controlId={`${fieldName}.moreDesc`}>
							<Form.Control
								type='Text'
								name={`${fieldName}.moreDesc`}
                onChange={(e)=>setValue(`${fieldName}.moreDesc`, e.target.value)}
							/>
						</Form.Group>

						<Col lg={1}>
							{indexes[0] !== index && (
								<Button onClick={() => removeConviction(index)}>
									Remove
								</Button>
							)}{' '}
						</Col>
					</Row>
				);
			})}

			<Row className='mb-5'>
				<Col>
					<Button onClick={addConviction}>Add more</Button>
				</Col>
			</Row>

		</>
	);
};

export default Convictions;
